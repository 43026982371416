import {
  TOGGLE_MODAL,
  TOGGLE_DROPDOWN,
  UPDATE_SIDEBAR_SUB_SECTION,
  UPDATE_MODAL_DATA,
  TOGGLE_SIDEBAR,
  CLOSE_ALL_SIDEBARS,
  UPDATE_STATUS,
  UPDATE_SIDEBAR_PROPS,
  SET_IS_PREVIEW,
} from '../actions/types';

const initialState = {
  modals: [],
  sidebars: [],
  previews: [],
  dropdowns: [],
  status: '',
  status_message: '',
  isPreview: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_SIDEBAR_SUB_SECTION: {
      return {
        ...state,
        sidebarSubSection: action.payload,
      };
    }
    case TOGGLE_MODAL: {
      let modals = state.modals.slice();
      if (modals.find((modal) => modal.modalId === action.payload.modalId)) {
        modals = modals.filter((modal) => modal.modalId !== action.payload.modalId);
      } else {
        modals.push(action.payload);
      }
      return {
        ...state,
        modals,
      };
    }
    case UPDATE_MODAL_DATA: {
      const modals = state.modals.slice();
      const newModals = modals.map((modal) => {
        if (modal.modalId === action.payload.modalId) {
          return action.payload;
        }
        return modal;
      });
      return {
        ...state,
        modals: newModals,
      };
    }
    case TOGGLE_SIDEBAR: {
      let sidebars = state.sidebars.slice();
      if (sidebars.find((sidebar) => sidebar.id === action.payload.id)) {
        sidebars = sidebars.filter((modal) => modal.id !== action.payload.id);
      } else {
        sidebars.push(action.payload);
      }
      return {
        ...state,
        sidebars,
      };
    }
    case UPDATE_SIDEBAR_PROPS: {
      const sidebars = state.sidebars.slice();
      const newSidebars = sidebars.map((sidebar) => {
        if (sidebar.id === action.payload.id) {
          return action.payload;
        }
        return sidebar;
      });
      return {
        ...state,
        sidebars: newSidebars,
      };
    }
    case CLOSE_ALL_SIDEBARS:
      return {
        ...state,
        sidebars: [],
      };
    case UPDATE_STATUS:
      return {
        ...state,
        ...action.payload,
      };
    case TOGGLE_DROPDOWN: {
      let dropdowns = state.dropdowns.slice();
      if (dropdowns.find((d) => d.dropdownId === action.payload.dropdownId)) {
        dropdowns = dropdowns.filter((d) => d.dropdownId !== action.payload.dropdownId);
      } else {
        dropdowns.push(action.payload);
      }
      return {
        ...state,
        dropdowns,
      };
    }
    case SET_IS_PREVIEW: {
      return {
        ...state,
        isPreview: true,
      };
    }
    default:
      return state;
  }
}
